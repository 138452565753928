import Rails from "@rails/ujs";

document.addEventListener("DOMContentLoaded", () => {
  const topSearchTextInput = document.getElementById(
    "js-top-search-text-input",
  );
  const topSearchTextForm = document.getElementById("js-top-search-form");
  topSearchTextForm.addEventListener("submit", (e) => {
    if (topSearchTextInput.value != "") {
      gtag({
        event: "search",
        search_term: topSearchTextInput.value,
        search_event_id: topSearchTextInput.id,
      });
    }
  });

  const topSearchFilterInput = document.getElementById("js-modal-top-search");
  const ageMin = topSearchFilterInput.querySelector("#age_min");
  const ageMax = topSearchFilterInput.querySelector("#age_max");
  const genderMale = topSearchFilterInput.querySelector("#gender_male");
  const genderFemale = topSearchFilterInput.querySelector("#gender_female");
  const incomeYearlyMin =
    topSearchFilterInput.querySelector("#income_yearly_min");
  const incomeYearlyMax =
    topSearchFilterInput.querySelector("#income_yearly_max");

  const topSearchFilterForm = Array.from([
    ageMin,
    ageMax,
    genderMale,
    genderFemale,
    incomeYearlyMin,
    incomeYearlyMax,
  ]);
  topSearchFilterForm.forEach((l) => {
    l.addEventListener("input", (e) => {
      if (!l.checkValidity()) return;
      gtag({
        event: "search_filter",
        age_min: ageMin.value,
        age_max: ageMax.value,
        income_yearly_min: incomeYearlyMin.value,
        income_yearly_max: incomeYearlyMax.value,
        gender: genderMale.checked
          ? genderMale.value
          : genderFemale.checked
            ? genderFemale.value
            : "",
        search_event_id: topSearchFilterInput.id,
      });
      Rails.fire(
        document.getElementById("js-top-search-filter-form"),
        "submit",
      );
    });
  });

  const topSearchFilterClear = document.getElementById(
    "js-top-search-filter-clear",
  );
  topSearchFilterClear.addEventListener("click", (e) => {
    ageMin.value = "";
    ageMax.value = "";
    incomeYearlyMin.value = "";
    incomeYearlyMax.value = "";
    genderMale.checked = false;
    genderFemale.checked = false;
    Rails.fire(document.getElementById("js-top-search-filter-form"), "submit");
  });
});
